<template>
  <div id="wrapper">
    <nav class="navbar bg-transparent position-absolute w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="navbar-brand bars" @click="this.$router.push({name: 'MyDeliveries'})">
          <i class="ph-arrow-left text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
        </div>

        <span class="navbar-left">
          <div class="navbar-brand">
            <span style="display: -webkit-inline-box;">
              <h4 style="margin-top: 0.2rem">
              <i class="ph-bell-ringing text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
              </h4>
            </span>
          </div>      
        </span>

        <div class="divider mt-4 d-none"></div>
      </div>
    </nav>

    <div class="backdrop" @click="close()"></div>

    <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;">

        <div class="card-header bg-transparent border-0 text-center pt-4 pb-1" v-if="screen == 0">
          <div class="row">
            <h6 class="text-uppercase fw-bold text-orange" style="letter-spacing: 1px">Active Delivery</h6>
          </div>
        </div>        
        
        <div class="card-body" v-if="!delivery_data.delivery_from">
          <no-data class="mt-4" :message="'No Active Delivery'"></no-data>
        </div>

        <div class="card-body pt-0" v-if="delivery_data.delivery_from && screen == 0">
          <ul class="timeline">   
            <li :class="{ 'done': delivery_data.status == 'processing' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'created'}, {'text-primary': delivery_data.status != 'created'}, 'fw-bold', 'mb-0']">Created</h6>
                </div>

                <p>Processing Delivery</p>
            </li> 

            <li :class="{ 'done': delivery_data.status == 'pending' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'pending'}, {'text-primary': delivery_data.status != 'pending'}, 'fw-bold', 'mb-0']">Pending</h6>
                </div>

                <p>Delivery Waiting for Driver</p>
            </li>  

            <li :class="{ 'done': delivery_data.status == 'driver en-route' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'dispatched'}, {'text-primary': delivery_data.status != 'dispatched'}, 'fw-bold', 'mb-0']">Dispatched</h6>
                </div>

                <p>Delivery Dispatched</p>
            </li> 

            <li :class="{ 'done': delivery_data.status == 'reached' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'reached'}, {'text-primary': delivery_data.status != 'reached'}, 'fw-bold', 'mb-0']">At Destination</h6>
                </div>

                <p>Driver at destination</p>
            </li>  

            <!-- <li :class="{ 'done': delivery_data.status == 'paid' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'paid'}, {'text-primary': delivery_data.status != 'dispatched'}, 'fw-bold', 'mb-0']">Paid For</h6>
                </div>

                <p>Delivery Paid For</p>
            </li>  -->
            
            <li :class="{ 'done': delivery_data.status == 'waiting confirmation' }">
                <div class="d-flex mb-3">
                    <h6 :class="[{'text-success': delivery_data.status == 'waiting confirmation'}, {'text-primary': delivery_data.status != 'waiting confirmation'}, 'fw-bold', 'mb-0']">Confirm Completion</h6>
                </div>

                <p>Confirm Completion</p>
            </li>                                                                
          </ul>

            <button class="btn bg-orange-secondary text-white w-100" v-if="delivery_data.status == 'waiting confirmation'" @click="changeStatus('completed')">
              Confirm Completion <font-awesome-icon class="ms-2" :icon="['fa', 'check-circle']"></font-awesome-icon>
            </button>
        </div>

        <div class="card-body mb-4" v-if=" !delivery_data.delivery_from && screen == 1">
          <h4 class="mx-auto text-center cancel mb-0">
            <font-awesome-icon class="fw-bold icon text-light" :icon="['fas', 'check']"></font-awesome-icon>
          </h4>

          <h4 class="text-center mt-3 fw-bold text-orange-secondary">Delivery Completed!</h4>

          <span v-if=" !rating.rating">
            <p class="text-center">Please give us your feedback on the process.</p>

            <rating :delivery_detail_id="this.delivery_data.id"></rating>  

            <router-link :to="{name: 'Home'}" class="text-center text-muted fw-bold mt-2 text-decoration-none float-  end">
              Skip
            </router-link>            
          </span>

          <span class="text-center mt-4" v-if="rating">
            <h6>
              <span class="fw-bold">Rating:</span> 
              <span class="fst-italic ms-1">{{ rating.rating }}/5</span>  
            </h6>

            <div class="d-flex mt-4">
              <router-link :to="{name: 'MyDeliveries'}" class="btn bg-orange-secondary text-white mx-auto">
                Go back
              </router-link>
            </div>
          </span>
        </div>
    </div>

    <div id="map" style="height: 100%"></div>       
  </div>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  ul.timeline {
    list-style-type: none;
    position: relative;
  }

  ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }

  ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
  }

  ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
  }

  ul.timeline > li.done:before {
    content: ' ';
    background: #198754;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #198754;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
  }

  .icon {
    background-color: $orange-secondary-color;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 15px;
  }  
</style>

<script>

  import mapFunctions from '../mixins/mapFunctions'
  import NoData from '../components/NoData'
  import Rating from '../components/Rating'

  export default {
    name: 'Home',
    mixins: [mapFunctions],  
    props: ['delivery_id'],
    components:  {
      NoData,
      Rating
    },
    data() {
      return {
        delivery_data: {},
        screen: 0,
        rating: null
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.getLatestDelivery()
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      getLatestDelivery() {
        this.axios.get('api/delivery/' + this.delivery_id).then(response => {
          this.delivery_data = response.data.data
          console.log(response.data)
          this.getRating()
        })
      },
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } ,
      changeStatus(value) {
        let data = {
          status: value
        }
        this.axios.put('api/delivery/' + this.delivery_data.id, data).then(() => {
          this.delivery_data.status = value
          this.subscribe('Delivery status changed from ' + data.status + ' to ' + this.delivery_data.status +'!')

          if(value == 'completed') {
            this.screen = 1
          }

        }).catch(errors => {
          console.log(errors);
        })
      },
      getRating() {
        if(this.delivery_data
          && this.delivery_data.id) {
          this.axios.get('api/rating/' + this.delivery_data.id).then((response) => {
            this.rating = response.data.data
          }).catch(errors => {
            console.log(errors)
          })
        }
      }                     
    }    
  }
</script>
